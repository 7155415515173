// 1 - import svgs
import ArrowLeft1 from "images/icons/arrow-left-1.svg";
import ArrowRight1 from "images/icons/arrow-right-1.svg";
import Bin from "images/icons/bin.svg";
import Bin2 from "images/icons/bin-2.svg";
import Load from "images/icons/load.svg";
import LoadingHalf from "images/icons/loading-half.svg";
import PencilWrite2 from "images/icons/pencil-write-2.svg";
import Remove from "images/icons/remove.svg";
import Upload from "images/icons/upload.svg";
import Cross from "images/icons/cross.svg";
import View from "images/icons/view.svg";
import EditPen from "images/icons/edit-pen.svg";
import Edit from "images/icons/edit.svg";
import LeftArrow from "images/icons/leftArrow.svg";
import Search from "images/icons/search.svg";
import Refresh from "images/icons/refresh.svg";
import Menu from "images/icons/menu.svg";
import MultipleUsersAdd from "images/icons/multiple-users-add.svg";
import Cog from "images/icons/cog.svg";
import Logout from "images/icons/logout.svg";
import UserWhite from "images/icons/userWhite.svg";
import UserBlack from "images/icons/userBlack.svg";
import Users from "images/icons/users.svg";
import Play from "images/icons/play.svg";
import Stop from "images/icons/stop.svg";
import Add from "images/icons/add.svg";
import Eye from "images/icons/eye.svg";
import Settings from "images/icons/settings.svg";
import Info from "images/icons/info.svg";
import Check from "images/icons/check.svg";
import Alert from "images/icons/alert.svg";
import Filter from "images/icons/filter.svg";
import Plus from "images/icons/plus.svg";
import InterFaceLock from "images/icons/interfaceLock.svg";
import MailSendEmail from "images/icons/mailSendEmail.svg";
import RemoveWhite from "images/icons/remove-white.svg";
import CrossWhite from "images/icons/cross-white.svg";
// 2 - SVGList
const iconMap = {
  ArrowLeft1,
  ArrowRight1,
  Bin,
  Bin2,
  Cog,
  Load,
  LoadingHalf,
  Logout,
  PencilWrite2,
  Remove,
  RemoveWhite,
  Upload,
  Cross,
  CrossWhite,
  View,
  EditPen,
  Edit,
  LeftArrow,
  Search,
  Refresh,
  Menu,
  MultipleUsersAdd,
  UserWhite,
  UserBlack,
  Users,
  Play,
  Stop,
  Add,
  Eye,
  Settings,
  Info,
  Check,
  Alert,
  Filter,
  Plus,
  InterFaceLock,
  MailSendEmail,
};
export default iconMap;
